import SvgSelector from "@/shared/UI/SvgSelector";
import { List, ListWrapper, StyleItem } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { setGraph } from "@/redux/filterSlice";
import { useEffect, useState } from "react";
import { Fade } from "@mui/material";

export const GraphBox = ({ search, filterStyles }) => {
  const dispatch = useDispatch();
  const { style } = useSelector((state) => state.filter);

  const [list, setList] = useState(filterStyles);

  useEffect(() => {
    list?.some((elem) =>
      elem.name.toLowerCase().startsWith(search.toLowerCase())
    ) &&
      setList(
        filterStyles
          .slice(0)
          .sort((x, y) =>
            x.name.toLowerCase().startsWith(search.toLowerCase())
              ? -1
              : y.name.toLowerCase().startsWith(search.toLowerCase())
              ? 1
              : 0
          )
      );
  }, [search]);

  return (
    <Fade in={true}>
      <List>
        <ListWrapper>
          {list?.map((item) => (
            <StyleItem
              active={style === item.id}
              onClick={() =>
                dispatch(setGraph(style === item.id ? undefined : item.id))
              }
              key={item.id}
            >
              {item.name}
              <SvgSelector svg={"tick"} />
            </StyleItem>
          ))}
        </ListWrapper>
      </List>
    </Fade>
  );
};
