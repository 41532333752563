import { setAlphabet } from "@/redux/filterSlice";
import { Title } from "../components";
import { BottomType, BottomTypes, WrapperBottom } from "./components";
import { useDispatch, useSelector } from "react-redux";

export const ByAlphabet = ({ filters }) => {
  const dispatch = useDispatch();

  const { script } = useSelector((state) => state.filter);

  return (
    <WrapperBottom>
      <Title>по алфавиту</Title>
      <BottomTypes>
        {filters?.map((data) => (
          <BottomType
            disabled={!data.is_available}
            onClick={() =>
              dispatch(setAlphabet(script === data.id ? undefined : data.id))
            }
            active={script === data.id}
            key={data.id}
          >
            {data.name}
          </BottomType>
        ))}
      </BottomTypes>
    </WrapperBottom>
  );
};
