import SvgSelector from "@/shared/UI/SvgSelector";
import { Title } from "../components";
import { ArchTypes, Type, Wrapper } from "./components";
import { useDispatch, useSelector } from "react-redux";
import { setArchetype } from "@/redux/filterSlice";
import { useMediaQuery } from "@mui/material";
import { breakpoints } from "@/styles/variables/variables";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/free-mode";
import "swiper/css";

import { FreeMode } from "swiper/modules";
import { FontTooltip } from "@/components/index-page/cards/card/Tooltip";

export const ByArchetype = ({ filters }) => {
  const dispatch = useDispatch();
  const { archetype } = useSelector((state) => state.filter);

  const handleTypeClick = (data) => {
    dispatch(setArchetype(archetype === data?.id ? undefined : data?.id));
  };

  const isMobile = useMediaQuery(breakpoints.mobile);
  return (
    <Wrapper>
      <Title>
        <span>по архетипу</span>
        <FontTooltip
          text={
            "Архетипы — универсальные образы&#8209;примитивы, с которыми ассоциируется шрифт"
          }
        />
      </Title>
      {isMobile ? (
        <Swiper
          modules={[FreeMode]}
          freeMode={true}
          spaceBetween={8}
          slidesPerView={"auto"}
          // centeredSlides
          initialSlide={Math.floor(filters.length / 3)}
        >
          {filters?.map((data) => (
            <SwiperSlide key={data.id}>
              <Type
                disabled={!data.is_available}
                onClick={() => handleTypeClick(data)}
                active={archetype === data.id}
              >
                <SvgSelector svg={"filter-arch-" + data.slug} />
                <span>{data.name}</span>
              </Type>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <ArchTypes>
          {filters?.map((data) => (
            <Type
              disabled={!data.is_available}
              onClick={() => handleTypeClick(data)}
              active={archetype === data.id}
              key={data.id}
            >
              <SvgSelector svg={"filter-arch-" + data.slug} />
              <span>{data.name}</span>
            </Type>
          ))}
        </ArchTypes>
      )}
    </Wrapper>
  );
};
