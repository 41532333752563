import Head from "next/head";

export const Meta = () => (
  <Head>
    <title>Шрифтотека</title>
    <meta
      name="description"
      content="Коллекция бесплатных кириллических и латинских шрифтов, разбитых по категориям, для графического дизайна, типографики, коммерческих или личных проектов."
    />
    <meta content="origin" id="mref" name="referrer" />
    <link
      href="https://artpavlushin.ru/tableau.json"
      rel="yandex-tableau-widget"
    ></link>
    {/* <meta name="yandex-verification" content="ec0efbac8d70b6a9" /> */}
    <meta
      content="https://artpavlushin.ru/tableau.png, color=#141414"
      name="yandex-tableau-widget"
    />
    <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
    <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
    <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
    <link rel="manifest" href="/site.webmanifest" />
    <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#141414" />
    <meta name="msapplication-TileColor" content="#141414" />
    <meta name="theme-color" content="#141414"></meta>
    <link rel="canonical" href="https://artpavlushin.ru/fonts" />

    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content="https://artpavlushin.ru/fonts" />
    <meta property="twitter:title" content="Шрифтотека Артели" />
    <meta
      property="twitter:description"
      content="Коллекция бесплатных кириллических и латинских шрифтов, разбитых по категориям, для графического дизайна, типографики, коммерческих или личных проектов."
    />
    <meta property="twitter:image" content="/seo_og.jpeg" />
    <meta property="og:type" content="website" />
    <meta property="og:url" content="https://artpavlushin.ru/fonts" />
    <meta property="og:title" content="Шрифтотека Артели" />
    <meta
      property="og:description"
      content="Коллекция бесплатных кириллических и латинских шрифтов, разбитых по категориям, для графического дизайна, типографики, коммерческих или личных проектов."
    />
    <meta property="og:image" content="/seo_og.jpeg" />
  </Head>
);
