import { DialogContent, Fade, useMediaQuery } from "@mui/material";
import {
  BoxTune,
  Filters,
  FiltersBottom,
  Head,
  Reset,
  Title,
} from "./components";
import { ByAlphabet } from "./filters/ByAlphabet";
import { ByArchetype } from "./filters/ByArchetype";
import { ByCategory } from "./filters/ByCategory";
import { ByForm } from "./filters/ByForm";
import { ByLicense } from "./filters/ByLicense";
import { useDispatch, useSelector } from "react-redux";
import { resetFilter } from "@/redux/filterSlice";
import { useMemo, useState } from "react";
import { ByGraph } from "./graph/ByGraph";
import { GraphBox } from "./graph/GraphBox";
import SvgSelector from "@/shared/UI/SvgSelector";
import { Back } from "@/components/header/userContent/settings/components";
import { InputSearch } from "./graph/InputSearch";
import { breakpoints } from "@/styles/variables/variables";
import { CloseButton } from "@/components/header/loginTabs/components";

export const SearchMenu = ({ graph, closePopup }) => {
  const { data: filters, error } = useSelector(
    (state) => state.availableFilters
  );

  const [openGraph, setOpenGraph] = graph;
  const [searchValue, setSearchValue] = useState("");

  const isMobile = useMediaQuery(breakpoints.mobile);

  const handleClose = () => {
    setOpenGraph(false);
    setSearchValue("");
  };

  const filterStyles = useMemo(() => {
    const { style } = filters;
    return style?.length > 0
      ? [...style]
          .filter((el) => el.is_available)
          .sort((a, b) => b.name.localeCompare(a.name))
      : undefined;
  }, [filters.style]);

  return (
    <BoxTune>
      <Head>
        {openGraph && (
          <Back onClick={handleClose}>
            <SvgSelector svg={"arrow-back"} />
            <span>Назад</span>
          </Back>
        )}
        <span>Настроить</span>
        {isMobile && (
          <CloseButton onClick={closePopup}>
            <SvgSelector svg={"close-popup"} />
          </CloseButton>
        )}
      </Head>
      {openGraph && (
        <>
          <Title mt={true}>По графическому стилю</Title>
          <InputSearch search={[searchValue, setSearchValue]} />
        </>
      )}
      {error ? (
        <Title>
          Произошла ошибка при загрузке данных, попробуйте перезагрузить
          страницу
        </Title>
      ) : (
        <>
          <DialogContent scroll="paper">
            {openGraph ? (
              <GraphBox filterStyles={filterStyles} search={searchValue} />
            ) : (
              <Fade in={true}>
                <Filters>
                  <ByCategory
                    filters={filters.category}
                    subcategories={filters.subcategory}
                  />
                  <FiltersBottom>
                    <ByLicense filters={filters.license} />
                    <ByForm filters={filters.shape} />
                    <ByAlphabet filters={filters.script} />
                  </FiltersBottom>
                  <ByArchetype filters={filters.archetype} />
                  <ByGraph
                    onClick={() => setOpenGraph(true)}
                    filters={filters.style}
                  />
                </Filters>
              </Fade>
            )}
          </DialogContent>
          <ResetButton closeGraph={handleClose} />
        </>
      )}
    </BoxTune>
  );
};

const ResetButton = ({ closeGraph }) => {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.filter);

  const isdisabled = useMemo(
    () => Object.keys(data).every((key) => data[key] === undefined),
    [data]
  );

  return (
    <Reset
      disabled={isdisabled}
      onClick={() => {
        dispatch(resetFilter());
        closeGraph();
      }}
    >
      Сбросить
    </Reset>
  );
};
