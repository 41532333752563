import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";
import { Title } from "../components";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${mixins.gap12}

  @media ${breakpoints.mobile} {
    gap: 3.135vw;
  }

  & .swiper {
    width: 100%;
    overflow: visible !important;
    &-slide {
      width: fit-content;
    }
  }

  & > ${Title} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
export const Types = styled.ul`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  ${mixins.gap12}

  @media ${breakpoints.mobile} {
    gap: 2.6vw;
  }
`;

export const Categories = styled(Types)`
  justify-content: center;
`;

export const CategorySt = styled.button`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
  position: relative;
  ${mixins.gap12}
  max-width: 84px;
  @media ${breakpoints.desktopXl} {
    max-width: 6.568vw;
    gap: 0.469vw;
  }
  @media ${breakpoints.tabletL} {
    max-width: 8.211vw;
    gap: 0.587vw;
  }
  @media ${breakpoints.tabletM} {
    max-width: 11.306vw;
    gap: 0.808vw;
  }
  @media ${breakpoints.mobile} {
    max-width: 20.063vw;
    gap: 2.508vw;
  }
  & > svg {
    &:first-child {
      position: absolute;
      top: 18.5%;
      left: 50%;
      transform: translateX(-50%);
      width: 46%;
      height: 34%;
      @media ${breakpoints.mobile} {
        top: 18%;
        height: 37%;
      }
    }
    &:nth-child(2) {
      height: 84px;
      width: 84px;
      @media ${breakpoints.desktopXl} {
        height: 6.57vw;
        width: 6.57vw;
      }
      @media ${breakpoints.tabletL} {
        height: 8.21vw;
        width: 8.21vw;
      }
      @media ${breakpoints.tabletM} {
        height: 11.3vw;
        width: 11.3vw;
      }
      @media ${breakpoints.mobile} {
        height: 20.063vw;
        width: 20.063vw;
      }
      & > path {
        &:first-child {
          transition: fill-opacity 0.3s;
        }
        &:nth-child(2) {
          transition: stroke-opacity 0.3s;
          ${({ active }) => active && `stroke-opacity: 0.3;`}
        }
      }
    }
  }
  & > span {
    ${fonts.manrope5}
    ${mixins.fs12}
    line-height: 116%;
    letter-spacing: 0.24px;
    @media ${breakpoints.mobile} {
      font-size: 3.135vw;
    }
  }

  &:hover {
    & > svg {
      &:nth-child(2) {
        // bg
        & > path:first-child {
          fill-opacity: 0.04;
        }
      }
    }
  }
  &:disabled {
    pointer-events: none;
    & > span {
      color: var(--wh12);
    }
    & > svg {
      &:nth-child(2) {
        // bg
        & > path {
          &:first-child {
            fill-opacity: 0.02;
          }
          &:nth-child(2) {
            stroke-opacity: 0;
          }
        }
      }
      &:first-child > path {
        fill-opacity: 0.04;
      }
    }
  }
`;

export const CategoryTypes = styled.ul`
  display: flex;
  flex-wrap: wrap;
  ${mixins.gap4}
  align-items: center;
  justify-content: center;
  margin-top: 12px;
  @media ${breakpoints.desktopXl} {
    margin-top: 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    margin-top: 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    margin-top: 1.615vw;
  }
  @media ${breakpoints.mobile} {
    gap: 2.508vw;
    margin-top: 4.389vw;
  }
`;

export const ArchTypes = styled(Types)`
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  column-gap: 0;
  row-gap: 12px;
  @media ${breakpoints.desktopXl} {
    row-gap: 0.938vw;
  }
  @media ${breakpoints.tabletL} {
    row-gap: 1.173vw;
  }
  @media ${breakpoints.tabletM} {
    row-gap: 1.615vw;
  }
  @media ${breakpoints.mobile} {
    row-gap: 3.762vw;
  }
`;

export const Type = styled.button`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  align-items: center;
  text-align: center;
  ${mixins.gap8}
  &:last-child {
    & > span {
      letter-spacing: -0.24px;
    }
  }
  & > span {
    ${fonts.manrope5}
    ${mixins.fs12}
    line-height: 117%;
    letter-spacing: 0.24px;
  }
  & > svg {
    width: 52px;
    height: 52px;
    & path,
    rect {
      transition: fill-opacity 0.3s;
      ${({ active }) => active && `fill-opacity: 0.4;`}
    }

    @media ${breakpoints.desktopXl} {
      width: 4vw;
      height: 4vw;
    }
    @media ${breakpoints.tabletL} {
      width: 5vw;
      height: 5vw;
    }
    @media ${breakpoints.tabletM} {
      width: 7vw;
      height: 7vw;
    }
    @media ${breakpoints.mobile} {
      width: 16.3vw;
      height: 16.3vw;
    }
  }

  &:hover {
    & > svg path,
    rect {
      fill-opacity: 0.3;
    }
  }

  &:disabled {
    pointer-events: none;
    color: var(--wh12);
    & > svg {
      opacity: 0.35;
    }
  }
`;

export const WrapperBottom = styled(Wrapper)`
  ${mixins.gap8}
`;

export const BottomTypes = styled(Types)`
  ${mixins.gap4}
  justify-content: center;
`;
export const BottomType = styled.button`
  height: 28px;
  display: flex;
  align-items: center;
  padding: 0 9px 1px;
  border-radius: 21px;
  background-color: var(--wh02);
  transition: background-color 0.3s, border-color 0.3s;
  border: 1.4px solid;
  ${fonts.manrope5}
  ${mixins.fs12}
  line-height: 116%;
  letter-spacing: 0.24px;
  ${({ active }) => `border-color: ${active ? "var(--wh03)" : "transparent"};`}

  @media ${breakpoints.desktopXl} {
    height: 2.187vw;
    padding: 0 0.7vw 1px;
  }
  @media ${breakpoints.tabletL} {
    height: 2.73vw;
    padding: 0 0.879vw 1px;
  }
  @media ${breakpoints.tabletM} {
    height: 3.768vw;
    padding: 0 1.6vw 1px;
  }
  @media ${breakpoints.mobile} {
    height: 8.777vw;
    padding: 2.194vw 3.762vw 2.821vw;
    font-size: 3.135vw;
  }

  &:hover {
    background-color: var(--wh04);
  }

  &:disabled {
    pointer-events: none;
    color: var(--wh12);
  }
`;

export const CategoryType = styled(BottomType)``;
