import SvgSelector from "@/shared/UI/SvgSelector";
import {
  InputContainerGraph,
  InputGraph,
  InputWrapperGraph,
} from "./components";
import { CloseButton, LoupeButton } from "@/components/search/components";

export const InputSearch = ({ search }) => {
  const [searchValue, setSearchValue] = search;

  return (
    <InputWrapperGraph loading={false}>
      <InputContainerGraph>
        <InputGraph
          onChange={(e) => setSearchValue(e.target.value)}
          value={searchValue}
          type="text"
          name="search"
          id="search-input-filter"
        />
      </InputContainerGraph>
      <CloseButton
        show={searchValue?.length > 1}
        onClick={() => setSearchValue("")}
      >
        <SvgSelector svg={"close-search"} />
      </CloseButton>
      <LoupeButton>
        <SvgSelector svg={"loupe"} />
      </LoupeButton>
    </InputWrapperGraph>
  );
};
