import { Slide, Zoom, useMediaQuery } from "@mui/material";
import { SearchDialogSt } from "./components";
import { breakpoints } from "@/styles/variables/variables";
import { useState } from "react";
import { SearchMenu } from "./SearchMenu";

export const SearchDialog = ({ open, handleClose }) => {
  const [openGraph, setOpenGraph] = useState(false);
  const isMobile = useMediaQuery(breakpoints.mobile);

  return (
    <SearchDialogSt
      fullScreen={isMobile}
      onClose={handleClose}
      id="settings-popup-menu"
      TransitionComponent={isMobile ? Slide : Zoom}
      TransitionProps={{ direction: "up" }}
      open={open}
      scroll={openGraph ? "body" : "paper"}
    >
      <SearchMenu graph={[openGraph, setOpenGraph]} closePopup={handleClose} />
    </SearchDialogSt>
  );
};
