import Layout from "@/components/Layout";
import CardsContainer from "@/components/index-page/cards/CardsContainer";
import { SearchBox } from "@/components/search/SearchBox";
import Wrapper from "@/shared/UI/Wrapper";
import { Disclaimer } from "@/components/index-page/disclaimer/Disclaimer";
import { resetFilterSearch, setFilterSearch } from "@/redux/searchInputSlice";
import { SearchDialog } from "@/components/index-page/search/popup/SearchDialog";
import { Meta } from "@/shared/seo/library";

const LibraryPage = () => {
  return (
    <>
      <Meta />
      <Layout logoSvg={"logo-fonts"}>
        <SearchBox
          resetFilterSearch={resetFilterSearch}
          setFilterSearch={setFilterSearch}
          SearchDialog={SearchDialog}
        />
        <Wrapper>
          <CardsContainer />
        </Wrapper>
      </Layout>
      <Disclaimer />
    </>
  );
};

export default LibraryPage;
