import SvgSelector from "@/shared/UI/SvgSelector";
import { Title } from "../components";
import {
  Categories,
  CategorySt,
  CategoryType,
  CategoryTypes,
  Wrapper,
} from "./components";
import { useDispatch, useSelector } from "react-redux";
import { setCategory, setCategoryType } from "@/redux/filterSlice";
import { Fade } from "@mui/material";

export const ByCategory = ({ filters, subcategories }) => {
  const dispatch = useDispatch();
  const { category, subcategory } = useSelector((state) => state.filter);

  return (
    <Wrapper>
      <Title>по категории</Title>
      <Categories>
        {filters?.map((data) => (
          <Category
            disabled={!data.is_available}
            onClick={() => {
              dispatch(setCategory(category === data.id ? undefined : data.id));
            }}
            active={category === data.id}
            key={data.id}
            data={data}
          />
        ))}
      </Categories>
      {subcategories && (
        <CategoryTypes>
          {subcategories.map((type, i) => (
            <Fade
              key={type.id}
              in={true}
              timeout={300}
              style={{ transitionDelay: 30 * i }}
            >
              <div>
                <CategoryType
                  disabled={!type.is_available}
                  onClick={() => {
                    dispatch(
                      setCategoryType(
                        subcategory === type.id ? undefined : type.id
                      )
                    );
                  }}
                  active={subcategory === type.id}
                >
                  {type.name}
                </CategoryType>
              </div>
            </Fade>
          ))}
        </CategoryTypes>
      )}
    </Wrapper>
  );
};

const Category = (props) => {
  return (
    <CategorySt
      disabled={props.disabled}
      active={props.active}
      onClick={props.onClick}
    >
      <SvgSelector svg={"filter-category-" + props.data.slug} />
      <SvgSelector svg={"filter-category-bg"} />
      <span>{props.data.name}</span>
    </CategorySt>
  );
};
