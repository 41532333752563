import {
  Artel,
  Help,
  PopupHeader,
} from "@/components/header/loginTabs/components";
import { useAccess } from "@/hooks/useAccess";
import SvgSelector from "@/shared/UI/SvgSelector";
import { isFull, isOverdue } from "@/shared/constants/access";
import { helpLink } from "@/shared/constants/links";
import { breakpoints } from "@/styles/variables/variables";
import { DialogContent, Fade, Slide, Zoom, useMediaQuery } from "@mui/material";
import { useEffect, useState } from "react";
import {
  BoxD,
  ButtonAccept,
  DialogD,
  DisclaimerLabel,
  Head,
  List,
} from "./components";

export const Disclaimer = () => {
  const [access] = useAccess();

  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (isFull(access) || isOverdue(access)) {
      setOpen(JSON.parse(localStorage.getItem("disclaimer")) ?? true);
    }
  }, [access]);

  const isMobile = useMediaQuery(breakpoints.mobile);

  const handleClose = () => {
    localStorage.setItem("disclaimer", false);
    setOpen(false);
  };
  return (
    <DialogD
      scroll="paper"
      fullScreen={isMobile}
      id="disclaimer-menu"
      TransitionComponent={isMobile ? Slide : Zoom}
      TransitionProps={{ direction: "up" }}
      open={open}
    >
      <BoxD>
        {isMobile && (
          <Fade in={true} timeout={700}>
            <PopupHeader>
              <Artel>
                <SvgSelector svg={"logo-artel"} />
              </Artel>
            </PopupHeader>
          </Fade>
        )}
        <DialogContent scroll="paper">
          <div>
            <Fade in={true} timeout={700} style={{ transitionDelay: 70 }}>
              <Head>
                <div>
                  <SvgSelector svg={"disclaimer"} />
                  <span>Дисклеймер</span>
                </div>
                {isMobile || (
                  <Help
                    href={helpLink}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Помощь
                  </Help>
                )}
              </Head>
            </Fade>
            <Fade in={true} timeout={700} style={{ transitionDelay: 130 }}>
              <List>
                <li>
                  <span>1.</span>
                  <span>
                    Вы подписались на доступ к сайту, где мы собираем шрифты,
                    с бесплатной лицензией для коммерческого или персонального
                    использования
                  </span>
                </li>
                <li>
                  <span>2.</span>
                  <span>
                    Сами шрифты и лицензию на них мы не продаём, просто
                    показываем, права принадлежат авторам
                  </span>
                </li>
              </List>
            </Fade>
            <ButtonAccept onClick={handleClose}>
              <SvgSelector svg={"button-accept-before"} />
              {isMobile ? (
                <SvgSelector svg={"button-accept-mobile"} />
              ) : (
                <SvgSelector svg={"button-accept"} />
              )}
              <span>Принято</span>
            </ButtonAccept>
            <DisclaimerLabel>
              Всё понятно, больше не показывать эти буквы
            </DisclaimerLabel>
            {isMobile && (
              <Help href={helpLink} target="_blank" rel="noopener noreferrer">
                Помощь
              </Help>
            )}
          </div>
        </DialogContent>
      </BoxD>
    </DialogD>
  );
};
