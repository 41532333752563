import SvgSelector from "@/shared/UI/SvgSelector";
import { Title } from "../components";
import { Choose, Wrapper } from "./components";
import { useSelector } from 'react-redux';

export const ByGraph = ({onClick}) => {
  const { style } = useSelector((state) => state.filter);
  return (
    <Wrapper active={style} onClick={onClick}>
      <Title>По графическому стилю</Title>
      <Choose>
        <span>выбрать</span>
        <SvgSelector svg={"filter-graph-arrow"} />
      </Choose>
    </Wrapper>
  );
};
