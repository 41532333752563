import {
  Input,
  InputContainer,
  InputWrapper,
} from "@/components/search/components";
import { breakpoints, fonts, mixins } from "@/styles/variables/variables";
import styled from "styled-components";

export const Wrapper = styled.button`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 16px;
  padding: 24px 16px 24px 28px;
  transition: background-color 0.3s, border-color 0.3s;
  border-radius: 16px;
  ${({ active }) =>
    `border: ${active ? "1.2px solid var(--wh3)" : "1px solid var(--wh08)"};
`}
  &:hover {
    background-color: var(--wh02);
  }
  &:active {
    background-color: var(--wh04);
  }
  @media ${breakpoints.desktopXl} {
    padding: 1.876vw 1.251vw 1.876vw 2.189vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 2.346vw 1.564vw 2.346vw 2.737vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 3.23vw 2.153vw 3.23vw 3.769vw;
  }
  @media ${breakpoints.mobile} {
    padding: 6.897vw 5.016vw 6.897vw 5.643vw;
  }
`;

export const Choose = styled.div`
  display: flex;
  align-items: center;
  ${mixins.gap8}
  & > span {
    color: var(--wh4);
    text-align: right;
    ${fonts.manrope5}
    ${mixins.fs10}
    line-height: 120%;
    letter-spacing: 0.2px;
    @media ${breakpoints.mobile} {
      display: none;
    }
  }
  & > svg {
    width: 12px;
    height: 12px;
    @media ${breakpoints.desktopXl} {
      width: 0.938vw;
      height: 0.938vw;
    }
    @media ${breakpoints.tabletL} {
      width: 1.173vw;
      height: 1.173vw;
    }
    @media ${breakpoints.tabletM} {
      width: 1.615vw;
      height: 1.615vw;
    }
    @media ${breakpoints.mobile} {
      width: 3.762vw;
      height: 3.762vw;
    }
  }
`;

// input

export const InputWrapperGraph = styled(InputWrapper)`
  width: 89%;
  height: 36px;
  margin: 12px 0;
  flex-shrink: 0;
  @media ${breakpoints.desktopXl} {
    height: 2.815vw;
    margin: 0.938vw 0;
  }
  @media ${breakpoints.tabletL} {
    height: 3.519vw;
    margin: 1.173vw 0;
  }
  @media ${breakpoints.tabletM} {
    height: 4.845vw;
    margin: 1.615vw 0;
  }
  @media ${breakpoints.mobile} {
    height: 11.285vw;
    margin: 3.762vw 0;
  }
`;

export const InputContainerGraph = styled(InputContainer)`
  width: 83%;
  @media ${breakpoints.mobile} {
    width: 73%;
  }
`;
export const InputGraph = styled(Input)`
  text-overflow: ellipsis;
  ${mixins.fs14}
  @media ${breakpoints.mobile} {
    font-size: 3.762vw;
  }
`;

// list

export const List = styled.ul`
  border-radius: 24px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  padding: 4px 0;
  @media ${breakpoints.desktopXl} {
    padding: 0.313vw 0;
    border-radius: 1.876vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 0.391vw 0;
    border-radius: 2.346vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 0.538vw 0;
    border-radius: 3.23vw;
  }
  @media ${breakpoints.mobile} {
    padding: 1.254vw 0;
    border-radius: 7.524vw;
  }
`;
export const ListWrapper = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 434px;
  height: 100%;
  overflow: auto;
  @media ${breakpoints.mobile} {
    max-height: 100%;
  }
`;
export const StyleItem = styled.button`
  & > svg {
    position: absolute;
    right: 14px;
    top: 50%;
    transform: translateY(-50%);
    width: 12px;
    height: 12px;
    transition: opacity 0.3s;
    ${({ active }) => `opacity: ${active ? 1 : 0};`}

    @media ${breakpoints.desktopXl} {
      right: 1.095vw;
      width: 0.938vw;
      height: 0.938vw;
    }
    @media ${breakpoints.tabletL} {
      right: 1.369vw;
      width: 1.173vw;
      height: 1.173vw;
    }
    @media ${breakpoints.tabletM} {
      right: 1.884vw;
      width: 1.615vw;
      height: 1.615vw;
    }
    @media ${breakpoints.mobile} {
      right: 4.389vw;
      width: 3.762vw;
      height: 3.762vw;
    }
  }
  position: relative;
  text-align: left;
  padding: 12px 60px 12px 16px;
  color: var(--wh3);
  ${fonts.manrope5}
  ${mixins.fs14}
  line-height: 114%;
  letter-spacing: 0.28px;
  transition: background-color 0.3s;
  ${({ active }) =>
    `background-color: ${active ? "var(--wh08)" : "var(--wh02)"};`}

  &:hover {
    ${({ active }) =>
      `background-color: ${active ? "var(--wh12)" : "var(--wh04)"};`}
  }
  @media ${breakpoints.desktopXl} {
    padding: 0.938vw 4.691vw 0.938vw 1.251vw;
  }
  @media ${breakpoints.tabletL} {
    padding: 1.173vw 5.865vw 1.173vw 1.564vw;
  }
  @media ${breakpoints.tabletM} {
    padding: 1.615vw 8.075vw 1.615vw 2.153vw;
  }
  @media ${breakpoints.mobile} {
    padding: 3.762vw 18.809vw 3.762vw 5.016vw;
    font-size: 3.762vw;
  }
`;
