import { useDispatch, useSelector } from "react-redux";
import { Title } from "../components";
import { BottomType, BottomTypes, WrapperBottom } from "./components";
import { setLicense } from "@/redux/filterSlice";
import { FontTooltip } from "@/components/index-page/cards/card/Tooltip";

export const ByLicense = ({ filters }) => {
  const dispatch = useDispatch();

  const { license } = useSelector((state) => state.filter);

  return (
    <WrapperBottom>
      <Title>
        <span>по лицензии</span>
        <FontTooltip
          text={
            "Фри (Free) — полностью бесплатные шрифты, разрешены для использования в любых проектах без ограничений\n\nЛичн (SemiFree)— шрифты для персонального использования, разрешены для учебных, личных и некоммерческих проектов"
          }
        />
      </Title>
      <BottomTypes>
        {filters?.map((data) => (
          <BottomType
            disabled={!data.is_available}
            onClick={() =>
              dispatch(setLicense(license === data.id ? undefined : data.id))
            }
            active={license === data.id}
            key={data.id}
          >
            {data.name}
          </BottomType>
        ))}
      </BottomTypes>
    </WrapperBottom>
  );
};
